@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.app {
  width: 100vw;
  height: 100vh;
  background: url(./assets/Desert.jpg);
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page {
  width: 75vw;
  height: 67vh;
  color: #fff;
  font-family: 'Raleway', sans-serif;
}