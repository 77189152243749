.intro {
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.intro-text {
    color: rgb(233, 217, 0);
    font-size: 3rem;
}

.slider {    
    background-color: rgb(51, 51, 51);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    transform: translateY(100%);
}

.hide {
    background: black;
    overflow: hidden;
}

.hide span {
    transform: translatey(100%);
    display: inline-block;
}

@media screen and (max-width:768px) {
    .intro-text {
        color: rgb(233, 217, 0);
        font-size: 1.25rem;
    }
}