.avatar {
    position: absolute;
    right:50px;
    top: 50px;
    vertical-align: middle;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid rgb(51, 51, 51);
    opacity: 0.8;
}

.avatar:hover {        
    cursor: pointer;    
    opacity: 1;
}

.avatar img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width:768px) {
    .avatar {
        width: 50px;
        height: 50px;
        right:25px;
        top: 25px;
    }
}
