@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@100;200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@100;200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lobster+Two:ital,wght@1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.app {
  width: 100vw;
  height: 100vh;
  background: url(/static/media/Desert.9a539a0b.jpg);
  background-size: cover;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.page {
  width: 75vw;
  height: 67vh;
  color: #fff;
  font-family: 'Raleway', sans-serif;
}
.page-header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: baseline;
            align-items: baseline;
    height: 25%;
    opacity: 0;
    -webkit-animation: fade 0.5s ease forwards 0.2s;
            animation: fade 0.5s ease forwards 0.2s;
    font-family: 'Playfair Display', serif;    
    color: #fff;
}

.page-body {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    font-weight: 400;
    line-height: 25px;
    height: 75%;
    opacity: 0;
    -webkit-animation: fade 0.5s ease forwards 0.5s;
            animation: fade 0.5s ease forwards 0.5s;
    overflow: auto;
}

.page-body::-webkit-scrollbar {
    background-color: rgba(233, 217, 0, 0.575);
    border-radius: 20px;
    width: 5px;
}

.page-body::-webkit-scrollbar-thumb {
    background-color: rgb(51, 51, 51);
    border-radius: 25px;
}

@-webkit-keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.about-content {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    height: 100%;
}

.about-content .about-description {
    height: 40%;
}

.tools {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.tools .tools-subtitle {
    font-size: large;
    margin-left: 15px;
}

.tools .tools-information {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.tools .tools-information .tool-card {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 125px;
    height: 100px;
}

.tools .tools-information .tool-card .tool-icon {
    font-size: 35px;
}

@media screen and (max-width:768px) { 
    .tools {
        margin-top: 10rem;
    }
}
.body-content {
    height: 100%;
}

.contact-subtitle {
    height: 15%;
    font-size: large;
    margin-left: 15px;
}

.contact-links {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 85%;    
}

.contact-links .contact-link {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    text-decoration: none;
    margin-bottom: 15px;
    color: #fff;
}

.contact-link:hover {
    color: rgb(233, 217, 0);    
}

.contact-card {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
}

.contact-card .contact-icon {
    font-size: 35px;
    margin: 0 15px;
}
.avatar {
    position: absolute;
    right:50px;
    top: 50px;
    vertical-align: middle;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid rgb(51, 51, 51);
    opacity: 0.8;
}

.avatar:hover {        
    cursor: pointer;    
    opacity: 1;
}

.avatar img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width:768px) {
    .avatar {
        width: 50px;
        height: 50px;
        right:25px;
        top: 25px;
    }
}

nav {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    min-height: 8vh;
    min-width: 75vw;
    font-family: 'Raleway', sans-serif;
    color: #fff;
}

/* .logo {
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: 20px;
} */

.nav-links {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    width: 50%;
    transition: width 1s;
}

.nav-links li {
    list-style: none;
}

.nav-links a:hover {
    color: rgb(233, 217, 0);
}

.nav-links a {
    color: #fff;
    text-decoration: none;
    letter-spacing: 3px;
    font-weight: bold;
    font-size: 14px;
}

.burger {
    color: #fff;
    margin: 5px;
    display: none;
    cursor: pointer;
    z-index: 2;
}

.logo {
    color: rgb(233, 217, 0);
    font-size: 1.5rem;
    font-family: 'Lobster Two', cursive;
}

.burger:hover {
    color: rgb(233, 217, 0);
}

@media screen and (max-width:1024px) {
    .nav-links {
        width: 70%;
    }
}

@media screen and (max-width:768px) {
    .nav-links {
        position: absolute;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        -webkit-flex-direction: column;
                flex-direction: column;
        left: 0px;
        bottom: 8vh;
        height: 20vh;        
        overflow: hidden;
        -webkit-transform: translateY(100%);
                transform: translateY(100%);
        transition: visibility 0.5s ease-out, -webkit-transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out, visibility 0.5s ease-out;
        transition: transform 0.5s ease-in-out, visibility 0.5s ease-out, -webkit-transform 0.5s ease-in-out;
        visibility: hidden;
        padding-left: 1rem;
        padding-top: 1rem;        
        z-index: 2;
    }
    
    .nav-links li {
        height: 25%;
        opacity: 0;
    }

    .burger {
        display: block;
    }
}

.nav-links.active {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    visibility: visible;    
}

.out-nav-links {
    display: none;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}

.out-nav-links.active {
    display: block;
}

@-webkit-keyframes navLinkFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes navLinkFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes navLinkFadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes navLinkFadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@-webkit-keyframes navLinkFadeBackgroundIn {
    from {
        background-color: transparent;
    }
    to {
        background-color: rgb(39, 39, 39, 0.9);
    }
}

@keyframes navLinkFadeBackgroundIn {
    from {
        background-color: transparent;
    }
    to {
        background-color: rgb(39, 39, 39, 0.9);
    }
}

@-webkit-keyframes navLinkFadeBackgroundOut {
    from {
        background-color: rgb(39, 39, 39, 0.9);
    }
    to {
        background-color: transparent;
    }
}

@keyframes navLinkFadeBackgroundOut {
    from {
        background-color: rgb(39, 39, 39, 0.9);
    }
    to {
        background-color: transparent;
    }
}
.intro {
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.intro-text {
    color: rgb(233, 217, 0);
    font-size: 3rem;
}

.slider {    
    background-color: rgb(51, 51, 51);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
}

.hide {
    background: black;
    overflow: hidden;
}

.hide span {
    -webkit-transform: translatey(100%);
            transform: translatey(100%);
    display: inline-block;
}

@media screen and (max-width:768px) {
    .intro-text {
        color: rgb(233, 217, 0);
        font-size: 1.25rem;
    }
}
.language-control {
    position: absolute;
    right: 150px;
    top: 77px;
    width: 75px;
    height: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    z-index: 1;
}

.nav-languages {    
    display: -webkit-flex;    
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    background-color: rgb(51, 51, 51);
    border: 0.5px solid rgba(15, 15, 15, 0.507);
    border-radius: 10px;
    overflow: hidden;
}

.nav-languages:hover {
    cursor: pointer;
}

.nav-languages li {
    list-style: none;
    border: 0.5px solid rgba(15, 15, 15, 0.507);
    width: 33.33%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.nav-languages li:hover {
    background-color: rgb(233, 217, 0);
}

.nav-languages a {    
    color: #fff;
    text-decoration: none;
}

.nav-languages a:hover {       
    color: rgb(51, 51, 51);
}

@media screen and (max-width:768px) {
    .language-control {
        width: 55px;
        height: 7px;
        right:100px;
        top: 47px;
        font-size: smaller;
    }
}
