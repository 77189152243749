.body-content {
    height: 100%;
}

.contact-subtitle {
    height: 15%;
    font-size: large;
    margin-left: 15px;
}

.contact-links {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    height: 85%;    
}

.contact-links .contact-link {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-decoration: none;
    margin-bottom: 15px;
    color: #fff;
}

.contact-link:hover {
    color: rgb(233, 217, 0);    
}

.contact-card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.contact-card .contact-icon {
    font-size: 35px;
    margin: 0 15px;
}